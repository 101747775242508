import request from "@/utils/request.js"
//登录
export const login =data =>{
    return request({
        url:"teacher/login",
        method:'post',
        data
    })
}
//退出登录
export const logout =data =>{
    return request({
        url:"teacher/logout",
        method:'post',
        data
    })
}
//我的直播课程

//直播列表
export const teacher_live =data =>{
    return request({
        url:"teacher_live/index",
        method:'post',
        data
    })
}
//获取推流码
export const get_live_code =data =>{
    return request({
        url:"teacher_live/get_live_code",
        method:'post',
        data
    })
}
//上传录像
export const up_live_video =data =>{
    return request({
        url:"teacher_live/up_live_video",
        method:'post',
        data
    })
}
//关闭直播
export const close_live =data =>{
    return request({
        url:"teacher_live/close_live",
        method:'post',
        data
    })
}
//禁言
export const dd_prohibition =data =>{
    return request({
        url:"teacher_live/add_prohibition",
        method:'post',
        data
    })
}
//全员禁言
export const set_live_banned =data =>{
    return request({
        url:"teacher_live/set_live_banned",
        method:'post',
        data
    })
}


//获取直播间聊天记录
export const get_chat_list =data =>{
    return request({
        url:"index/get_chat_list",
        method:'post',
        data
    })
}

//获取直播间成员
export const get_live_user =data =>{
    return request({
        url:"index/get_live_user",
        method:'post',
        data
    })
}

//获取直播间全员禁言状态
export const get_live_banned =data =>{
    return request({
        url:"teacher_live/get_live_banned",
        method:'post',
        data
    })
}
//班级信息
//我的班级
export const get_my_class =data =>{
    return request({
        url:"teacher_live/get_my_class",
        method:'post',
        data
    })
}

//班级成员
export const get_my_class_mx =data =>{
    return request({
        url:"teacher_live/get_my_class_mx",
        method:'post',
        data
    })
}
//个人中心
//个人信息
export const index =data =>{
    return request({
        url:"teacher/index",
        method:'post',
        data
    })
}

//修改自我介绍
export const update_bio =data =>{
    return request({
        url:"teacher/update_bio",
        method:'post',
        data
    })
}
//修改头像
export const update_avatar =data =>{
    return request({
        url:"teacher/update_avatar",
        method:'post',
        data
    })
}

//重置密码
export const resetpwd =data =>{
    return request({
        url:"teacher/resetpwd",
        method:'post',
        data
    })
}

//设置直播间
export const set_live =data =>{
    return request({
        url:"teacher_live/set_live",
        method:'post',
        data
    })
}
//直播间抓拍照片列表
export const get_live_study_images =data =>{
    return request({
        url:"teacher_live/get_live_study_images",
        method:'post',
        data
    })
}

//直播文档
export const get_live_word =data =>{
    return request({
        url:"teacher_live/get_live_word",
        method:'post',
        data
    })
}
// 课程类别
export const get_subject_list =data =>{
    return request({
        url:"teacher_live/get_subject_list",
        method:'post',
        data
    })
}
//出题列表
export const get_student_questions =data =>{
    return request({
        url:"teacher_live/get_student_questions",
        method:'post',
        data
    })
}
//记录出题
export const add_live_questions =data =>{
    return request({
        url:"teacher_live/add_live_questions",
        method:'post',
        data
    })
}
//获取统计结果
export const get_live_questions_list =data =>{
    return request({
        url:"teacher_live/get_live_questions_list",
        method:'post',
        data
    })
}
//获取统计答题详情
export const get_question_user_list =data =>{
    return request({
        url:"teacher_live/get_question_user_list",
        method:'post',
        data
    })
}




