<template>
  <div class="header">
    <div class="header-content">
      <el-avatar :src="info.avatar"></el-avatar>
      <span class="header-content-name">{{ info.nickname }}</span>
      <span class="logout" @click="logout"
        >退出登录 <i class="el-icon-switch-button"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { logout, index } from "@/api/index";
import local from "@/utils/local";
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.$store.dispatch("getUserinfo");
    this.info = this.$store.state.userinfo;
  },

  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  methods: {
    logout() {
      logout().then((res) => {
        if (res.data.code == 1) {
          local.clear();
          this.$router.push("/login");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  watch: {
    userinfo(newVal) {
      console.log(newVal);
      this.info = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .header-content {
    display: flex;
    align-items: center;

    .header-content-name {
      margin: 0 20px;
    }

    .logout {
      cursor: pointer;
      position: relative;
    }
    .logout::before {
      content: "";
      height: 23px;
      background: #ccc;
      width: 2px;
      position: absolute;
      left: -9px;
    }
  }
}
</style>