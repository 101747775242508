// axios封装-通用工具函数
// 引入axios
import axios from 'axios'
import local from './local';
import router from '@/router/index'
// 服务器地址 写上以后 所有的请求 会自动在前面拼接这个地址
axios.defaults.baseURL = 'https://xjz-api.luodada.wang/api/';
// axios.defaults.baseURL = 'http://192.168.2.13/api/';

axios.defaults.timeout = 3000;  // 超时

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  //     // 在发送请求之前做些什么
  let token = local.get("token")
  if (token) {
    //    请求之前携带token，用户认证
    config.headers.token = token
  }




  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  sessionStorage.setItem("code", JSON.stringify(''))
  // 对响应数据做点什么
  return response;
}, function (error) {
  console.log(error.response.status);
  sessionStorage.setItem("code", JSON.stringify(error.response.status))
  if (error.response.data.code == 401) {
    // local.set("bool",true)
    router.push("/login")
  }
  console.log(error);
  // 对响应错误做点什么
  return Promise.reject(error);

});
export default axios