export function copyText(copytext) {
    const text = document.createElement('input'); // 创建节点
    text.setAttribute('readonly', 'readonly');
    text.value = copytext; // 赋值
    document.body.appendChild(text); // 插入节点
    text.setSelectionRange(0, text.value.length);
    text.select(); // 选中节点
    document.execCommand('copy'); // 执行浏览器复制方法
    if (document.body.removeChild(text)) {
        this.$message({ type: 'success', message: '复制成功' })
    } else {
        this.$message({ type: 'error', message: '复制失败' })
    }
}
