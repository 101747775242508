<template>
    
        <el-container >
            <el-aside width="200px"><left-nav/></el-aside>
            <el-container>
                <el-header><Header/></el-header>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
  
</template>

<script>
import LeftNav from "./com/left-nav.vue"
import Header from "./com/header.vue"
export default {
    components:{
        LeftNav,
        Header
    }

}
</script>

<style lang="scss" scoped>
.el-container{
    height: 100%;
}
 ::v-deep .el-header{
    height: 80px !important;
}
.el-main{
    background: #F5F6F6;
    padding-top: 18px;
}
.el-header{
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 

}
</style>