<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
   mounted() {
     this.$ws.initWebpack();
     this.$store.state.ws.onmessage = (val) => {
        let msg = JSON.parse(val.data);
        if(msg.msg_type == "ping"){
          this.$store.state.ws.send("教师端嘣嘣");
        }
      }
  },
  methods:{

  },
    destroyed() {
    this.$store.state.lockReconnect=false
    // 1。组件销毁时，关闭与服务器的连接
    if (this.$store.state.ws) {
      this.$store.state.ws.close();
    }

  },
};
</script>

<style lang="scss" scoped>
.app {
  height: 100%;
}
</style>
