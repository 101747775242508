import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入公共样式
import "@/assets/css/common.css"
//引入组件库
import ElementUI from 'element-ui';
//引入图标样式
import "@/assets/font/iconfont.css"
import 'element-ui/lib/theme-chalk/index.css';
import { copyText } from '@/utils/copy'
import "./plugins/video.js"; // 引入刚刚定义的video.js文件
import moment from "moment"
Vue.prototype.$moment = moment;

//引入websocket
import wsConnect from "@/utils/websocket";
Vue.prototype.$ws = wsConnect
// 富文本
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);


Vue.prototype.copyText = copyText
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
