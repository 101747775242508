<template>
    <div class="nav">
        <div class="nav-header">学教长教师端</div>
        <el-menu
      :default-active="path"
      class="el-menu-vertical-demo"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#fff"
      @select="select"
      router>

      <el-menu-item :index="item.url" v-for="(item,index) in list " :key="index" :class="{'active-backg':item.url==path}">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
     
    </el-menu>
    </div>
</template>

<script>

    export default {
        data(){
            return{
                path:"",
                list:[
                {name:'我的课程',url:"/course",icon:"el-icon-video-camera"},
                {name:'班级信息',url:"/class",icon:"el-icon-s-finance"},
                {name:'个人中心',url:"/person",icon:"el-icon-user"},
                {name:'操作文档',url:"/document",icon:"el-icon-document"},
                ]
            }
        },
        methods:{
            select(e){
                this.path=e
            }
        },
        created(){
            this.path=this.$route.matched[0].path

            console.log(this.$route);
        }

    }
</script>

<style lang="scss" scoped>
.nav{
    height: 100%;
    background:#001529 ;
    .nav-header{
        font-size: 19px;
        color: #fff;
        line-height: 90px;
        text-align: center;
        height: 90px;
        
    }
}

::v-deep .el-menu{
    border: 0;
}
::v-deep .el-menu-item{
    padding-left: 30px !important;
    color: #808a94 !important;
}
::v-deep .active-backg{
    background: #1769FE !important;
    color: #fff !important;
}
</style>