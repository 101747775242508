import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "@/views/layout/index.vue"
Vue.use(VueRouter)
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}


const routes = [
  // 登录
  { path: "/login", component: () => import("@/views/login/index.vue") },
  { path: "/", component: home, redirect: '/login' },
  // 我的课程
  {
    path: "/course", component: home,
    redirect: '/course/play',
    children: [
      {
        path: "/course/play",
        component: () => import("@/views/course/index.vue"),
        meta: { title: '我的课程' },

      },

    ]
  },
  // 班级信息
  {
    path: "/class", component: home,
    redirect: '/class/information',
    meta: {
      title: '班级信息'
    },
    children: [
      {
        path: "/class/information",
        component: () => import("@/views/class/info.vue"),
        meta: {
          title: ''
        }
      
      },
      {
        path: "/class/information/student",
        component: () => import("@/views/class/student.vue"),
        meta: {
          title: '班级学员'
        }
      }



    ]
  },
  //个人中心
  {
    path: "/person", component: home,
    redirect: '/person/index',
    children: [
      {
        path: "/person/index", component: () => import("@/views/person/index.vue"), meta: {
          title: '个人中心'
        }
      }
    ]
  },
  {
    path: '/live',
    component: () => import("@/views/course/compontent/live.vue"),
    meta: {
      title: '直播间'
    }
  },
  {
    path: '/replay',
    component: () => import("@/views/course/compontent/replay.vue"),
    meta: {
      title: '回放'
    }
  },
  //操作文档
  {
    path: "/document", component: home,
    redirect: '/document/index',
    children: [
      {
        path: "/document/index",
        component: () => import("@/views/document/index.vue"),
        meta: { title: '操作文档' },

      },

    ]
  },
 


]
const router = new VueRouter({
  routes
})
//前置路由守卫
router.beforeEach((to, from, next) => {
  console.log(to.path);
  if (to.path !== '/login') {
    let token = localStorage.getItem("token")
    if (token) {
      
      next()
    } else {
      next('/login');
    }
  } else {
    next()
  }
})
export default router
