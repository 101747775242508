import Vue from 'vue'
import Vuex from 'vuex'
import {index} from "@/api/index"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userinfo:{},
    bool:false,
    permissions: false,
    url: '',
    //ws参数
    path: '',
    ws: null,//建立的连接
    lockReconnect: false,//是否真正建立连接
    timeout: 30*1000,//30秒一次心跳
    timeoutObj: null,//心跳心跳倒计时
    serverTimeoutObj: null,//心跳倒计时
    timeoutnum: null,//断开 重连倒计时
  },

  getters: {

  },
  mutations: {
    getuserinfo(state,val){
      console.log(val);
      state.userinfo=val
    },
    CHANGE(state,val){
      state.bool=val
     
    }
  },
  actions: {
    getUserinfo(context){
      index().then(res=>{
        if(res.data.code==1){
          let info=res.data.data
          context.commit("getuserinfo",info)
        }
       })
    }
   
  },
  modules: {
  }
})
export default store
