import store from "@/store";

function initWebpack() {
    var wsurl = 'wss://xjz-api.luodada.wang:10002'
    // var wsurl = 'ws://192.168.2.13:10002'

    store.state.ws = new WebSocket(wsurl);
    store.state.ws.onopen = onopen;
    store.state.ws.onmessage = onmessage;
    store.state.ws.onclose = onclose;
    store.state.ws.onerror = onerror;
}
function onopen() {
    console.log("教师端连接websocket");
    var params = '{"reqtype":"Query","action":"allexts"}'
    store.state.ws.send(params)
 
}

function onmessage(e) {
    console.log('教师端接收数据',e)

    //处理数据的地方
    // start()//心跳重置
    if (e.data) {  // 接收到消息
        // 自定义全局监听事件
        window.dispatchEvent(new CustomEvent('onmessageWS', {
          detail: {
            data: e.data
          }
        }))
      }
}
function onclose(e) {
    console.log('教师端websocket 断开: ', e);
   
  
     //lockReconnect为true在进行重连
   
        initWebpack()
   

}
function onerror(e) {
    console.log("教师端出现错误");
    //重连
    initWebpack()
}


export default {
    initWebpack,
    onmessage,
    onclose,
    onopen,
    onerror
}
